<template>
  <div>

    <!--          <b-alert-->
    <!--            variant="primary"-->
    <!--            show-->
    <!--          >-->
    <!--            <div class="alert-body font-small-2">-->
    <!--              <p>-->
    <!--                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>-->
    <!--              </p>-->
    <!--              <p>-->
    <!--                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>-->
    <!--              </p>-->
    <!--            </div>-->
    <!--            <feather-icon-->
    <!--              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"-->
    <!--              icon="HelpCircleIcon"-->
    <!--              size="18"-->
    <!--              class="position-absolute"-->
    <!--              style="top: 10; right: 10;"-->
    <!--            />-->
    <!--          </b-alert>-->

    <!-- alert -->
    <b-alert
      v-height-fade.appear
      variant="danger"
      :show="errorMessage!==null"
      class="mb-0"
    >
      <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        {{ errorMessage }}
      </div>
    </b-alert>
    <!-- form -->
    <validation-observer
      ref="authForm"
      #default="{invalid}"
    >
      <b-form
        id="login"
        class="auth-login-form mt-2"
        @submit.prevent="submit"
      >
        <!--phone-->
        <b-form-group
          :label="$t('auth.Phone')"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.Phone')"
            vid="phone"
            rules="required"
          >
            <VuePhoneNumberInput
              id="phone"
              v-model="phone.number"
              :error="phone.valid!==true"
              name="phone"
              valid-color="#28C76F"
              :required="true"
              :translations="{
                countrySelectorLabel: $t('Country code'),
                countrySelectorError: $t('Choose a country'),
                phoneNumberLabel: $t('Phone Number'),
                example: $t('Example:')
              }"
              :only-countries="countriesList"
              :default-country-code="countryCode"
              @update="onPhoneUpdate"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- email -->
          <!--              <b-form-group-->
          <!--                label="Email"-->
          <!--                label-for="login-email"-->
          <!--              >-->
          <!--                <validation-provider-->
          <!--                  #default="{ errors }"-->
          <!--                  name="Email"-->
          <!--                  vid="email"-->
          <!--                  rules="required|email"-->
          <!--                >-->
          <!--                  <b-form-input-->
          <!--                    id="login-email"-->
          <!--                    v-model="userEmail"-->
          <!--                    :state="errors.length > 0 ? false:null"-->
          <!--                    name="login-email"-->
          <!--                    placeholder="john@example.com"-->
          <!--                  />-->
          <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
          <!--                </validation-provider>-->
          <!--              </b-form-group>-->
        </b-form-group>
        <!-- password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="password">{{ $t('auth.Password') }}</label>
            <b-link :to="{name:'auth-forgot-password'}">
              <small>{{ $t('auth.forgot_password') }}?</small>
            </b-link>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Password')"
            vid="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="password"
                v-model="password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="password"
                placeholder="········"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  size="16"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <re-captcha
          :humanoid-verifying-required="humanoidVerifyingRequired"
          @humanoid-verified="(recaptchaToken)=>humanoidVerified(recaptchaToken)"
        />
        <!--OTP-->
        <otp-form
          :phone="getFormattedPhone()"
          :verifying-required="verifyingRequired"
          @phoneVerified="onPhoneVerified()"
        />

        <!-- checkbox -->
        <!--              <b-form-group>-->
        <!--                <b-form-checkbox-->
        <!--                  id="remember-me"-->
        <!--                  v-model="submitting"-->
        <!--                  name="checkbox-1"-->
        <!--                >-->
        <!--                  <small>{{ $t('auth.Remember Me') }}</small>-->
        <!--                </b-form-checkbox>-->
        <!--              </b-form-group>-->

        <!-- submit buttons -->
        <b-button
          v-if="!verifyingRequired"
          type="submit"
          variant="primary"
          block
          :disabled="invalid||submitting"
        >
          <span v-if="submitting">
            <b-spinner
              type="grow"
              small
            />
            {{ $t('auth.Authorization') }}
          </span>
          <span v-if="!submitting">
            {{ $t('auth.Sign in') }}
          </span>
        </b-button>
      </b-form>
    </validation-observer>

    <!-- divider -->
    <!--          <div class="divider my-2">-->
    <!--            <div class="divider-text">-->
    <!--              or-->
    <!--            </div>-->
    <!--          </div>-->

    <!-- social buttons -->
    <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
    <!--            <b-button-->
    <!--              variant="facebook"-->
    <!--              href="javascript:void(0)"-->
    <!--            >-->
    <!--              <feather-icon icon="FacebookIcon" />-->
    <!--            </b-button>-->
    <!--            <b-button-->
    <!--              variant="twitter"-->
    <!--              href="javascript:void(0)"-->
    <!--            >-->
    <!--              <feather-icon icon="TwitterIcon" />-->
    <!--            </b-button>-->
    <!--            <b-button-->
    <!--              variant="google"-->
    <!--              href="javascript:void(0)"-->
    <!--            >-->
    <!--              <feather-icon icon="MailIcon" />-->
    <!--            </b-button>-->
    <!--            <b-button-->
    <!--              variant="github"-->
    <!--              href="javascript:void(0)"-->
    <!--            >-->
    <!--              <feather-icon icon="GithubIcon" />-->
    <!--            </b-button>-->
    <!--          </div>-->

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BForm, BButton, BAlert, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'

import useJwt from '@/auth/jwt/useJwt'
import { required, email, digits } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { numbersOnly } from '@/mixins/functions'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { $themeConfig } from '@themeConfig'
import { pushNotification } from '@/views/apps/notification/useNotification'
import { locales } from '@/mixins/options'
import reCaptcha from './ReCaptcha.vue'
import otpForm from './OtpForm.vue'
import useAuth from '../useAuth'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    otpForm,
    reCaptcha,
  },

  mixins: [togglePasswordVisibility, numbersOnly],
  data() {
    return {
      phone: {
        number: '',
        valid: false,
        country: undefined,
      },
      submitting: false,
      verifyingRequired: false,
      password: '',
      userEmail: '',
      userPhone: '',
      // validation rules
      required,
      email,
      digits,
      errorMessage: null,
      humanoidVerifyingRequired: false,

    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    countryCode() {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode || 'UA'
    },
  },

  methods: {
    onPhoneVerified() {
      this.verifyingRequired = false
      this.submit()
    },
    onPhoneUpdate({
      countryCode, isValid, phoneNumber, countryCallingCode, formattedNumber,
    }) {
      this.phone.valid = isValid
      if (isValid) {
        this.userPhone = formattedNumber
        this.verifyingRequired = this.isPhoneUnverified(this.getFormattedPhone())
        // localStorage.setItem('countryCode', countryCode)
      }
    },
    getFormattedPhone() {
      return this.userPhone.replace(/\+/g, '')
    },
    humanoidVerified(recaptchaToken) {
      this.humanoidVerifyingRequired = false
      this.login(recaptchaToken)
    },
    submit() {
      this.$refs.authForm.validate().then(success => {
        if (success) {
          this.humanoidVerifyingRequired = true
        }
      })
    },
    login(recaptchaToken) {
      this.submitting = true
      useJwt.login({
        client_id: process.env.VUE_APP_API_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
        grant_type: 'password',
        username: this.getFormattedPhone(),
        password: this.password,
        recaptchaToken,
      })
        .then(response => {
          useJwt.setToken(response.data.access_token)
          useJwt.setRefreshToken(response.data.refresh_token)
          store.dispatch('account/getUserCurrent').then(currentUser => {
            const userData = currentUser.data
            this.$router.replace(getHomeRouteForLoggedInUser())
              // .then(() => {
              //   this.$toast({
              //     component: ToastificationContent,
              //     position: 'top-right',
              //     props: {
              //       title: `${this.$t('auth.Welcome')}, ${userData.name}`,
              //       icon: 'CoffeeIcon',
              //       variant: 'success',
              //       text: `${this.$t('auth.Now you can start to add or search requests for one time job!')}`,
              //     },
              //   })
              // })
              .then(() => {
                this.createFcmSubscription()
              })
          })
        })
        .catch(error => {
          this.errorMessage = this.$t(error.response.data.message)
          if (error.response.data.error === 'registration_unverified') {
            // Display OTP form
            this.verifyingRequired = true
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.submitting = false
          }, 1000)
        })
    },
  },
  setup() {
    const { isPhoneUnverified } = useAuth()
    const { appName } = $themeConfig.app
    const { createFcmSubscription } = pushNotification()
    const { countriesList } = locales()
    return {
      appName,
      isPhoneUnverified,
      createFcmSubscription,
      countriesList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.vue-phone-number-input .select-country-container {
  min-width: 90px!important;
  max-width: 90px!important;
}
.country-selector__input {
  padding-right: 0!important;
}
</style>
